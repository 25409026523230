<template>
  <div class="auth-failed">
    <img class="auth-failed__image" src="@/assets/img/authorization-failed.png" alt="auth failed image">

    <h1>{{ $t('authorizationFailed.text') }}</h1>

    <div class="auth-failed__buttons">
      <a :href="iosApp" role="button">
        <img src="@/assets/icons/appstore.svg" alt="download via app store" />
      </a>

      <a :href="androidApp" role="button">
        <img src="@/assets/icons/playmarket.svg" alt="download via play market" />
      </a>
    </div>
  </div>
</template>

<script>
import config from "../../config";

export default {
  name: "AuthFailed",
  data: () => ({
    iosApp: config.iosAppUrl,
    androidApp: config.androidAppUrl
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins/font.scss";
$titleColor: #1E1E1E;
$subTitleColor: #696969;

.auth-failed {
  max-width: 564px;
  margin: 71px auto 0;
  text-align: center;

  @media screen and (max-width: $mobile) {
    margin: 32px auto 0;
    padding: 0 12px;
  }

  &__image {
    margin-bottom: 11px;
    @media screen and (max-width: $mobile) {
      width: 250px;
    }
  }

  h1 {
    @include font(32px, 700, 36px, $titleColor, false);
    margin-bottom: 32px;

    @media screen and (max-width: $mobile) {
      @include font(22px, $ffIsInter: false);
      margin-bottom: 24px;
    }
  }

  &__buttons {
    max-width: 344px;
    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000000;
      width: 160px;
      height: 54px;
      border-radius: 14px;

      @media screen and (max-width: $mobile) {
        width: 100%;

        &:not(&:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>